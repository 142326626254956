import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import * as Style from '../../components/toppage.module.css'

const extlink = (url, text) => (
  <>
    <a href={url} className="text-decoration-none me-1" target="_blank" rel="noopener noreferrer">{text}</a>
  </>
)


const MediaPage = () => (
  <Layout>
    <Head pageTitle="Media Coverage - GAZIRU, Inc." pageLang="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>News</h4>

          <h5>11/11/2024</h5>
          <p>We exhibited our history management example using GAZIRU individual identification at 
            <a className="text-decoration-none mx-1" href="https://www.jimtof.org/en/index.html" target="_blank" rel="noopener noreferrer">
            JIMTOF2024</a>
            (Japan International Machine Tool Fair) held at Tokyo Big Sight.<br />
            In addition, our representative gave a lecture at the workshop 
            "Issue and Countermeasures against Traceability in Manufacturing
              (Individual Recognition by Image Recognition)."<br />
            Thank you very much for coming to our booth.</p>
          <hr />

          <h5>07/29/2024</h5>
          <p>Our image recognition engine "GAZIRU R," which realizes
            highly accurate type identification, has been incorporated into the voice
            guidance function of the "Artizon Museum Official App,"
            which was released on July 24, 2024.<br />
            Available from the "Place and Piece" exhibition starting July 27.</p>
            <div className={Style.ref}>
              <span>Reference:</span><br />
              <div className={Style.ref}>
                <a className="text-decoration-none mx-4" href="https://www.artizon.museum" target="_blank" rel="noopener noreferrer">
                ISHIBASHI FOUNDATION ARTIZON MUSEUM</a><br />
                <a className="text-decoration-none mx-5" href="https://www.artizon.museum/wp-content/uploads/2024/07/ATZ240725_A_J.pdf" target="_blank" rel="noopener noreferrer">
                Press Release</a><br />
                <a className="text-decoration-none mx-4" href="https://artexhibition.jp/topics/news/20240726-AEJ2229865/" target="_blank" rel="noopener noreferrer">
                Art Exhibition JAPAN</a>
              </div>
            </div><br />
          <hr />

          <h5>07/20/2023</h5>
          <p>A case study of using the DOBOT MG400 robotic arm to stabilize 
            the image capture position for GAZIRU System was presented
            at TechShare's DOBOT User Conference 2023 in Nagoya, Japan. 
            <a className="text-decoration-none mx-1" href="https://techshare.co.jp/innovation-week2023/" target="_blank" rel="noopener noreferrer">
            DOBOT User Conference 2023</a><br />
            Thank you for your participation.</p>
          <hr />

          <h5>03/03/2023</h5>
          <p>Exhibited at <a className="text-decoration-none mx-1" href="https://jid-ascii.com" target="_blank" rel="noopener noreferrer">
            JAPAN INNOVATION DAY</a>
            2023 hosted by ASCII STARTUP.<br />
            Thank you for your participation.</p>
          <hr />

          <h5>01/25/2023</h5>
          <p>An article about our company was published in Nihon Keizai Shimbun (Paper version).</p>
          <hr />


          <h5>11/12/2022</h5>
          <p>Our representative gave a lecture
            "Issue and Countermeasures against Traceability in Manufacturing
              (Individual Recognition by Image Recognition)" 
            in a workshop at
            <a className="text-decoration-none mx-1" href="https://www.jimtof.org/en/index.html" target="_blank" rel="noopener noreferrer">
            JIMTOF2022
            </a>
            held at Tokyo Big Sight.<br />
            Thank you for your participation.</p>
          <hr />

          <h5>07/15/2022</h5>
          <p>Office location is updated. Please see :
            <Link className="text-decoration-none mx-1" to="/en/about">About</Link></p>
          <hr />

          <h5>06/20/2022</h5>
          <p>An article about our company was published in Nihon Keizai Shimbun (Paper version).</p>
          <hr />

          <h5>10/19/2021</h5>
          <p>GAZIRU, Inc. concluded alliance with CAICA Technologies Inc. to expand sales of NFT platform.</p>
            <div className={Style.ref}>
              <span>Reference:</span><br />
              <div className={Style.ref}>
                <a className="text-decoration-none mx-1" href="https://www.caica.jp/wp-content/uploads/2021/10/20211019_1_oshirase.pdf" target="_blank" rel="noopener noreferrer">
                CAICA DIGITAL Inc.
                </a><br />
                <a className="text-decoration-none mx-1" href="https://www.caica-technologies.co.jp/wp-content/uploads/2021/10/20211019_2_CT.pdf" target="_blank" rel="noopener noreferrer">
                CAICA Technologies Inc.
                </a><br />
              </div>
            </div><br />
          <hr />

          <h5>10/12/2021</h5>
          <p>Our technology was introduced at NEC's web seminar for the manufacturing industry held on 10/05/2021.<br/>
            Thank you for your participation.</p>
          <hr />

          <h5>08/03/2021</h5>
          <p>An article describing our image recognition technology was published in
            {extlink("https://www.nikko-pb.co.jp/products/detail.php?product_id=5110",
              " Nippon Kogyo Shuppan, Image Labo, August 2021")}.</p>
          <hr />

          <h5>07/30/2021</h5>
          <p><a className="text-decoration-none me-2" href="https://www.youtube.com/watch?v=hSXelFIz_Uo" target="_blank" rel="noopener noreferrer">
              A video introducing the new features of the our smartphone application
             </a>is now available on Youtube</p>
          <hr />

          <h5>07/07/2021</h5>
          <p>Our technology was introduced at NEC's web seminar,
            "Secrets to Implementing AI to Strengthen the Manufacturing Industry" 
            held on 07/06/2021.<br />
            Thank you for your participation.</p>
          <hr />

          <h5>05/26/2021</h5>
          <p>GAZIRU Z Client V3.5, which support Gigabit Ethernet camera, is released.</p>
          <hr />

          <h5>05/18/2021</h5>
          <p>An article about our company was published in
            {extlink("https://japan.zdnet.com/article/35170886/",
              " ZDnet")}.</p>
          <hr />
          <h5>04/26/2021</h5>
          <p>An article about our company was published in
            {extlink("https://www.nikkei.com/article/DGXZQOUC132VZ0T10C21A4000000/",
              " Nikkei Sangyo Shimbun (Web version)")}.</p>
          <hr />
          <h5>04/20/2021</h5>
          <p>An article about our company was published in Nikkei Sangyo Shimbun (Paper version).</p>
          <hr />

          <h5>03/08/2021</h5>
          <p>Our technology was introduced at NEC's web seminar for the manufacturing industry held on 02/24/2021, 03/02/2021.<br />
            Thank you for your participation.</p>
          <hr />

          <h5>02/08/2021</h5>
          <p>Our product is featured in
            <a className="text-decoration-none mx-1" href="https://intrapreneurs-hub10.peatix.com/" target="_blank" rel="noopener noreferrer">
              Intrapreneur's Hub online event</a>
            held on 01/27/2021.<br />
            Thank you very much for watching.</p>
          <hr />

          <h5>12/09/2020</h5>
          <p>GAZIRU service was introduced at the Google Cloud Manufacturing
            Solutions Webinar held on 12/02/2020.<br />
            Thank you for your participation.</p>
          <hr />

          <h5>10/19/2020</h5>
          <p>Our product is featured in {extlink("https://ascii.jp/elem/000/004/029/4029516/",
            " ASCII.jp")}.</p>
          <hr />

          <h5>09/23/2020</h5>
          <p>GAZIRU Technology was intoroduced at
            <a className="text-decoration-none mx-1" href="https://www.intel.co.jp/content/www/jp/ja/now/ai-park-online.html" target="_blank" rel="noopener noreferrer">
            Online seminar
            </a>held by TOKYO ELECTRON DEVICE LIMITED.</p>
          <hr />

          <h5>09/14/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://news.mynavi.jp/article/20200908-1289094/",
              " Mynavi News")}.</p>
          <hr />
          <h5>09/14/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://techable.jp/archives/137179",
              " TECHABLE")}.</p>
          <hr />
          <h5>09/14/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://www.excite.co.jp/news/article/Dreaminnovation_vent_news_cTOlDnWvsE/",
              " Excite News")}.</p>
          <hr />
          <h5>09/08/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://www.e-logit.com/loginews/2016:102105.php",
              " ZAIKEI Shimbun")}.</p>
          <hr />
          <h5>09/08/2020</h5>
          <p>Press release:
            {extlink("https://prtimes.jp/main/html/rd/p/000000001.000062152.html",
              " PR Times")}.</p>
          <hr />

          <h5>09/08/2020</h5>
          <p>Press release:<br/>
          <a className="text-decoration-none mx-1" href="https://prtimes.jp/main/html/rd/p/000000001.000062152.html" target="_blank" rel="noopener noreferrer">
          GAZIRU, Inc., a provider of image recognition technology, begins offering personal identification services using object fingerprint recognition technology for the distribution industry.</a></p>
          <hr />

          <h5>08/30/2020</h5>
          <p>Our technology was introduced at NEC's web seminar for the manufacturing industry held on 08/19/2020, 08/25/2020.</p>
          <hr />

          <h5>07/21/2020</h5>
          <p>Featured in {extlink("https://www.nikkei.com/article/DGKKZO61751380Q0A720C2TJ1000/",
            " Nihon Keizai Shimbun")}.</p>
          <hr />
          <h5>07/20/2020</h5>
          <p> Selected for the first round of applications for
            {extlink("https://co-hr-innovation.jp/",
              " Ministry of Economy, Trade and Industry(METI)'s program")}</p>
          <hr />

          <h5>07/16/2020</h5>
          <p>Product introduction movie is now available.</p>
          <hr />

          <h5>06/02/2020</h5>
          <p>Product introduction movie is now available.</p>
          <hr />

          <h5>04/27/2020</h5>
          <p><Link className="text-decoration-none me-2" to="/en/examples">
          Showcasing examples page</Link>
          is now available.</p>
          <hr />


          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>

        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default MediaPage
